/*
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2024-03-03 00:58:58
 * @LastEditors: zdl 2694810584@qq.com
 * @LastEditTime: 2024-06-21 22:55:04
 * @FilePath: /横店/hengdian/src/router.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React, { lazy } from "react";
import { Outlet, useRoutes } from "react-router-dom";
const Index = lazy(() => import("./pages/HomePage/index"));
const AboutPage = lazy(() => import("./pages/AboutPage/index"));
const DirectionPage = lazy(() => import("./pages/DirectionPage/index"));
const CaseInvestment = lazy(() => import("./pages/CaseInvestment/index"));
const NewCaseInvestment = lazy(() => import("./pages/NewCaseInvestment/index"));
const NewsCenter = lazy(() => import("./pages/NewsCenter/index"));
const ContactPage = lazy(() => import("./pages/ContactPage/index"));
const NewsCenterDetails = lazy(() =>
  import("./pages/NewsCenter/NewsCenterDetails/index")
);
const CaseInvestmentDetails = lazy(() =>
  import("./pages/CaseInvestment/CaseInvestmentDetails/index")
);
const NewCaseInvestmentDetails = lazy(() =>
  import("./pages/NewCaseInvestment/CaseInvestmentDetails/index")
);

const Array = [
  {
    path: "/",
    element: (
      <React.Suspense fallback={<div>Loading...</div>}>
        <Index />
      </React.Suspense>
    ),
  },

  {
    path: "/:name",
    element: (
      <React.Suspense fallback={<div>Loading...</div>}>
        <Index />
      </React.Suspense>
    ),
  },
  {
    path: "/:name/about",
    element: (
      <React.Suspense fallback={<div>Loading...</div>}>
        <AboutPage />
      </React.Suspense>
    ),
  },
  {
    path: "/:name/direction",
    element: (
      <React.Suspense fallback={<div>Loading...</div>}>
        <DirectionPage />
      </React.Suspense>
    ),
  },

  // 新的投资案例
  {
    path: "/:name/invest",
    element: <Outlet></Outlet>,
    exact: true,
    children: [
      {
        index: true,
        element: (
          <React.Suspense fallback={<div>Loading...</div>}>
            <NewCaseInvestment />
          </React.Suspense>
        ),
      },
      {
        path: "caseInvestmentDetails",
        element: (
          <React.Suspense fallback={<div>Loading...</div>}>
            <NewCaseInvestmentDetails />
          </React.Suspense>
        ),
      },
    ],
  },

  // 老的 投资案例
  // {
  //   path: "/:name/invest",
  //   element: <Outlet></Outlet>,
  //   exact: true,
  //   children: [
  //     {
  //       index: true,
  //       element: (
  //         <React.Suspense fallback={<div>Loading...</div>}>
  //           <CaseInvestment />
  //         </React.Suspense>
  //       ),
  //     },
  //     {
  //       path: "caseInvestmentDetails",
  //       element: (
  //         <React.Suspense fallback={<div>Loading...</div>}>
  //           <CaseInvestmentDetails />
  //         </React.Suspense>
  //       ),
  //     },
  //   ],
  // },
  {
    path: "/:name/news",
    element: <Outlet></Outlet>,
    exact: true,
    children: [
      {
        index: true,
        element: (
          <React.Suspense fallback={<div>Loading...</div>}>
            <NewsCenter />
          </React.Suspense>
        ),
      },
      {
        path: "informations",
        element: (
          <React.Suspense fallback={<div>Loading...</div>}>
            <NewsCenterDetails />
          </React.Suspense>
        ),
      },
    ],
  },
  {
    path: "/:name/contact",
    element: (
      <React.Suspense fallback={<div>Loading...</div>}>
        <ContactPage />
      </React.Suspense>
    ),
  },
];
const WrappedRoutes = () => {
  return useRoutes(Array);
};
export default WrappedRoutes;
