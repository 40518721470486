/*
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2024-02-21 23:04:31
 * @LastEditors: zdl 2694810584@qq.com
 * @LastEditTime: 2024-05-09 22:52:04
 * @FilePath: /横店/hengdian/src/App.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React, { useEffect, useRef } from "react";
import WrappedRoutes from "./router";
import SiteHeader from "./components/SiteHeader/index";
import { isMobile } from "./util/index";
import "./App.css";

function App() {
  const numRef = useRef(null);

  const scorePOI = () => {
    // 处理滚动事件的代码
    const siteHeader = document.getElementsByClassName("siteHeader")[0];
    const pathname = window.location.pathname.split("/");
    if (pathname[pathname.length - 1]) {
      headerBg();
      return;
    }
    if (document.body.scrollTop + document.documentElement.scrollTop == 0) {
      // 隐藏
      if (siteHeader) {
        numRef.current.handleMouseLeave();
        siteHeader.removeAttribute("style");
      }
    }
    isSetHeaderBg();
  };

  const isSetHeaderBg = () => {
    const siteHeader = document.getElementsByClassName("siteHeader")[0];
    if (isMobile()) {
      // 移动端
    } else {
      // web端
      if (
        document.body.scrollTop + document.documentElement.scrollTop > 1 &&
        siteHeader.style.backgroundColor !== "rgba(16, 21, 5, 0.64)"
      ) {
        if (siteHeader) {
          headerBg();
        }
      }
    }
  };

  const headerBg = () => {
    const siteHeader = document.getElementsByClassName("siteHeader")[0];
    let rgba;
    if (isMobile()) {
    } else {
      rgba = "rgba(16, 21, 5, 0.64)";
      numRef.current.handleMouseEnter();
      siteHeader.style.backgroundColor = rgba;
      siteHeader.style.backdropFilter = "saturate(180%) blur(20px)";
    }
  };

  useEffect(() => {
    headerBg();
    const scroll = window.addEventListener("scroll", scorePOI);
    return () => {
      scroll && window.removeEventListener(scroll);
    };
  }, []);

  return (
    <div className="App">
      <SiteHeader ref={numRef}></SiteHeader>
      <WrappedRoutes></WrappedRoutes>
    </div>
    // <h1 class="animate__animated animate__bounce">An animated element</h1>
  );
}

export default App;
