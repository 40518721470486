/*
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2024-02-22 21:19:17
 * @LastEditors: zdl 2694810584@qq.com
 * @LastEditTime: 2024-10-28 21:51:43
 * @FilePath: /横店/hengdian/src/components/SiteHeader/index.jsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React, {
  useImperativeHandle,
  useState,
  forwardRef,
  useEffect,
} from "react";
import "./index.css";
import { SearchOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { isMobile, getlang, langsArr } from "../../util/index";

const navObj = {
  "zh-cn": [
    {
      text: "首页",
      key: "",
    },
    {
      text: "关于我们",
      key: "about",
    },
    {
      text: "关注方向",
      key: "direction",
    },
    {
      text: "投资案例",
      key: "invest",
    },
    {
      text: "新闻中心",
      key: "news",
    },
    {
      text: "联系我们",
      key: "contact",
    },
  ],
  en: [
    {
      text: "Home",
      key: "",
    },
    {
      text: "About us",
      key: "about",
    },
    {
      text: "Strategic focus",
      key: "direction",
    },
    {
      text: "Investment cases",
      key: "invest",
    },
    {
      text: "News",
      key: "news",
    },
    {
      text: "Contact us",
      key: "contact",
    },
  ],
};

const navObjPc = {
  "zh-cn": [
    {
      text: "首页",
      key: "",
    },
    {
      text: "关于我们",
      key: "about",
    },
    {
      text: "关注方向",
      key: "direction",
    },
    {
      text: "投资案例",
      key: "invest",
    },
    {
      text: "新闻中心",
      key: "news",
    },
    {
      text: "联系我们",
      key: "contact",
    },
  ],
  en: [
    {
      text: "About us",
      key: "about",
    },
    {
      text: "Strategic focus",
      key: "direction",
    },
    {
      text: "Investment cases",
      key: "invest",
    },
    {
      text: "News",
      key: "news",
    },
    {
      text: "Contact us",
      key: "contact",
    },
  ],
};

const pathnameArr = window.location.pathname.split("/");

const SiteHeader = forwardRef((props, ref) => {
  const [handleFixheader, setHandleFixheader] = useState(
    "siteHeader fixheader"
  );
  const navigate = useNavigate();

  // 手机
  const [menuToggleClass, setMenuToggleClass] = useState(true);

  const handleMouseEnter = () => {
    if (isMobile()) {
      return;
    }
    setHandleFixheader("siteHeader");
  };

  const handleMouseLeave = () => {
    if (isMobile()) {
      return;
    }
    if (document.body.scrollTop + document.documentElement.scrollTop < 1) {
      setHandleFixheader("siteHeader fixheader");
    }
  };

  useImperativeHandle(
    ref,
    () => ({ handleMouseEnter, handleMouseLeave }) //父组件通过ref获取值，要在这里抛出
  );

  const menuTogglePc = () => {
    const siteHeader = document.getElementsByClassName("siteHeader")[0];
    if (menuToggleClass) {
      setHandleFixheader("siteHeader fixheader");
      siteHeader.removeAttribute("style");
    } else {
      setHandleFixheader("siteHeader");
      if (!isMobile()) {
        siteHeader.style.backgroundColor = "rgba(0,0,0,0.2)";
      }
      if (document.body.scrollTop + document.documentElement.scrollTop < 1) {
        setHandleFixheader("siteHeader fixheader");
        siteHeader.removeAttribute("style");
      }
    }
    setMenuToggleClass(!menuToggleClass);
  };

  const renderCenFn = () => {
    if (isMobile()) {
      const i = pathnameArr.includes("en") ? "zh-cn" : "en";
      const txt = {
        // en: "EN",
        "zh-cn": "中",
      };
      // 手机端
      return (
        <div className="container containerNav">
          <div
            className={menuToggleClass ? "menuToggle" : "menuToggle on"}
            // className={"menuToggle"}
            onClick={menuTogglePc}
          >
            <div className="one"></div>
            <div className="two"></div>
            <div className="three"></div>
          </div>
          <div
            className="log"
            onClick={() => {
              navigate(`/${getlang()}/`);
            }}
          >
            <img
              src={require("../../asset/WeChatb9980848fed100e344158a75cba44095.png")}
            ></img>
          </div>
          <div
            className="menuToggle phoneLangs"
            style={{
              fontSize: "16px",
              fontWeight: "400",
              lineHeight: "20px",
              color: "rgba(16, 21, 5, 1)",
            }}
          >
            <a href={`/${i}/`}>{txt[i]}</a>
          </div>
          <div
            className="bb"
            style={menuToggleClass ? { width: "0px" } : { width: "100%" }}
            onClick={() => {
              setMenuToggleClass(true);
            }}
          >
            <div
              className={menuToggleClass ? "menu-over" : "menu-over showMenu"}
              // className={menuToggleClass ? "menu-over" : "menu-over showMenu"}
            >
              <div>
                {navObjPc[getlang()].map((key, index) => {
                  return (
                    <div
                      className="mobileNavItem"
                      key={index}
                      onClick={() => {
                        navigate(`/${getlang()}/${key.key}`);
                        menuTogglePc();
                      }}
                    >
                      <div
                        style={{
                          fontWeight: "600",
                          fontSize: "16px",
                          lineHeight: "24px",
                          letterSpacing: "4px",
                        }}
                      >
                        {key.text}
                      </div>
                      <div className="itemImg">
                        <img
                          src={require("../../asset/WeChat9db29c6d7180bb5d08fad13be1504f9e.png")}
                        ></img>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div></div>
            </div>
          </div>
        </div>
      );
    }
    // web
    return (
      <div className="container containerNav">
        <div
          className="log"
          onClick={() => {
            navigate(`/${getlang()}/?foo=bar&baz=qux`);
          }}
        >
          <img
            src={require("../../asset/WeChat0e1d628a034cd3e0f2d191a9c742e3c3.png")}
          ></img>
        </div>
        <div className="navigation">
          <div className="nav">
            <ul className="mainav">
              {navObj[getlang()].map((k, i) => {
                const pathnameArr = window.location.pathname.split("/");
                const current = pathnameArr[pathnameArr.length - 1];
                return (
                  <li
                    // style={{minWidth:"64px",maxWidth:"80px"}}
                    className={current && current == k.key ? "current" : ""}
                    key={i}
                    onClick={() => {
                      navigate(`/${getlang()}/${k.key}`);
                    }}
                  >
                    <a>{k.text}</a>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="langs" style={{ paddingRight: "0px" }}>
            {langsArr.map((k, i) => {
              return (
                <span key={k.url}>
                  <a
                    className={pathnameArr.includes(k.url) ? "active" : ""}
                    href={`/${k.url}/`}
                  >
                    {k.name}
                  </a>{" "}
                  {langsArr.length - 1 !== i ? "/" : ""}
                </span>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className={handleFixheader}>{renderCenFn()}</div>
      {/* <div onMouseLeave={handleMouseLeave} onMouseEnter={handleMouseEnter} className={handleFixheader }>
         {renderCenFn()}
        </div> */}
    </>
  );
});

export default SiteHeader;
